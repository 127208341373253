@import 'typography.css';
@import 'slick.css';
@import 'components.css';

@tailwind base;
@tailwind components;
@tailwind utilities;

html,
body {
  font-family: 'Chiswick';
  overflow-x: clip;
  @apply text-blackSand bg-sand;
}
