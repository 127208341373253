@layer components {
  .partners-grid {
    display: grid;
    gap: 1.5rem;
  }

  .grid-3-col-max {
    grid-template-columns: repeat(
      auto-fit,
      minmax(
        min(calc(100% - 1.5rem), max(320px, calc(33% - 1.5rem * 2 / 3))),
        1fr
      )
    );
  }

  /* Defines a grid which dynamically resizes to available space up to a max of 4 columns. To improve, we should consider
    extracting some of these magic numbers into dynamic values (for example the column gap value 1.5rem)
  */
  .grid-4-col-max {
    grid-template-columns: repeat(
      auto-fit,
      minmax(
        min(calc(100% - 1.5rem), max(248px, calc(25% - 1.5rem * 3 / 4))),
        1fr
      )
    );
  }
  /* .grid-4-col-max {
    grid-template-columns: repeat(
      auto-fit,
      minmax(min(calc(100% - 1.5rem), max(248px, calc(25% - 1.5rem * 3) / 4))),
      1fr
    );
  } */

  .featured-partners-grid {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(22.5%, 320px));
  }
  .sub-nav-item {
    @apply transition duration-100 border-b border-transparent whitespace-nowrap hover:border-blackSand;
  }

  .spacer-xxs {
    flex-shrink: 0;
    block-size: 8px;
  }
  .spacer-xs {
    flex-shrink: 0;
    block-size: 16px;
  }
  .spacer-sm {
    flex-shrink: 0;
    block-size: 24px;
  }
  .spacer-md {
    flex-shrink: 0;
    block-size: 32px;
  }

  .spacer-lg {
    flex-shrink: 0;
    block-size: 48px;
  }

  .spacer-xl {
    flex-shrink: 0;
    block-size: 56px;
  }

  .spacer-2xl {
    flex-shrink: 0;
    block-size: 64px;
  }

  .spacer-3xl {
    flex-shrink: 0;
    block-size: 80px;
  }

  .spacer-4xl {
    flex-shrink: 0;
    block-size: 88px;
  }

  .spacer-5xl {
    flex-shrink: 0;
    block-size: 128px;
  }

  .youtube-embed {
    aspect-ratio: 16 / 9;
    height: 100%;
    width: 100;
  }
}

ul.checked-list {
  @apply pl-0 list-none text-darkStone;
}

.three-col-infographic ul.checked-list {
  @apply pl-4 lg:pl-0;
}

ul.checked-list li {
  @apply relative pl-6 mb-4 md:mb-6;
}

ul.checked-list li:last-of-type {
  @apply mb-0;
}

ul.checked-list li::before {
  @apply md:top-[3px] top-[6px] left-0 absolute w-6 h-6 bg-no-repeat content-[''];
  background-image: url('https://media.foratravel.com/image/upload/v1727438208/checkmark-darkstone_sneq7j.svg');
}

ul.stone-check li::before {
  background-image: url('https://media.foratravel.com/image/upload/v1731431102/check-stone_aftwg9.svg');
}

ul.darkstone-check li::before {
  background-image: url('https://media.foratravel.com/image/upload/v1727438208/checkmark-darkstone_sneq7j.svg');
}

ul.black-check li::before {
  background-image: url('https://media.foratravel.com/image/upload/v1715948921/checkmark-black_b4nij3.svg');
}

ul.blackSand-check li::before {
  background-image: url('https://media.foratravel.com/image/upload/v1727438369/checkmark-blacksand_qnwu8a.svg');
}

ul.green-check li::before {
  background-image: url('https://media.foratravel.com/image/upload/v1727438721/checkmark-green_vnbe8w.svg');
}

.date-dropdown {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0;
}
.date-dropdown.show {
  opacity: 1;
  height: 100%;
  display: block;
}

@media (min-width: 768px) {
  .date-dropdown.show {
    animation: date-drop 0.2s ease forwards;
    opacity: 1;
    display: block;
    height: fit-content;
  }
}

@keyframes date-drop {
  0% {
    padding-bottom: 0;
    padding-top: 0;
    max-height: 0;
  }
  100% {
    padding-bottom: 32px;
    padding-top: 32px;
    max-height: 642px;
  }
}

.advisor-content .profile-block .global-richtext p {
  @apply capitalize fora-text-h4 md:fora-text-h7;
}

.advisor-content .profile-block.reduced .global-richtext p {
  @apply capitalize fora-text-callout-4 md:fora-text-callout-7;
}

.hotel-dropdown {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0;
  backdrop-filter: blur(0.3rem);
  @apply bg-darkSand bg-opacity-[.85];
}

.hotel-dropdown.show {
  animation: hotelDropdown 0.2s ease forwards;
  opacity: 1;
  display: block;
}

@keyframes hotelDropdown {
  0% {
    height: 0;
    opacity: 0;
    top: 34px;
  }
  100% {
    height: fit-content;
    opacity: 1;
    top: 34px;
  }
}

@keyframes hotelDropdown-bg {
  0% {
    height: 0;
    opacity: 0;
    top: 34px;
  }
  100% {
    height: 100%;
    opacity: 0.85;
    top: 34px;
  }
}

@keyframes hotelDropdown-blur-bg {
  0% {
    height: 0;
    opacity: 0;
    top: 34px;
  }
  100% {
    height: 100;
    opacity: 1;
    top: 34px;
  }
}

.image-gallery-overlay {
  position: fixed;
  inset: 0px;
  background-color: rgb(0, 0, 0);
  z-index: 50;
}

.image-gallery-overlay.ReactModal__Overlay {
  opacity: 0;
  transition: opacity 200ms cubic-bezier(0.2, 0, 0, 1);
}

.image-gallery-overlay.ReactModal__Overlay--after-open {
  opacity: 1;
}

.image-gallery-overlay.ReactModal__Overlay--before-close {
  opacity: 0;
}

.hotel-gallery-overlay.ReactModal__Overlay {
  opacity: 0;
  transform: translateY(25vh);
  transition: opacity 200ms cubic-bezier(0.2, 0, 0, 1),
    transform 300ms cubic-bezier(0.2, 0, 0, 1);
}

.hotel-gallery-overlay.ReactModal__Overlay--after-open {
  opacity: 1;
  transform: translateY(0vh);
}

.hotel-gallery-overlay.ReactModal__Overlay--before-close {
  transform: translateY(25vh);
  opacity: 0;
}

.hotel-gallery-content.ReactModal__Content {
  opacity: 0;
  transition: opacity 300ms 200ms cubic-bezier(0.2, 0, 0, 1);
}

.hotel-gallery-content.ReactModal__Content--after-open {
  opacity: 1;
}

.hotel-gallery-content.ReactModal__Content--before-close {
  opacity: 0;
}

.hotel-gallery-overlay {
  background-color: rgb(254, 250, 245);
  z-index: 50;
  position: fixed;
  inset: 0px;
}

.hotel-gallery-content {
  position: absolute;
  inset: 0px;
  border: none;
  background: transparent;
  overflow: auto;
  border-radius: 4px;
  outline: none;
  padding: 32px 28px;
  padding-top: 0;
  width: 100%;
  height: 100%;
}

@media (min-width: 768px) {
  .hotel-gallery-content {
    padding: 16px;
    padding-top: 0px;
  }
}

.destination-header-block h3,
.destination-header-block .page-blocks-page-content {
  @apply max-w-[891px];
}

.custom-pagination-container .button-next {
  background-image: url('https://media.foratravel.com/image/upload/v1727798508/icon-arrow-circle-right-black_thgnhs.svg');
  background-repeat: no-repeat;
}

.custom-pagination-container .button-prev {
  background-image: url('https://media.foratravel.com/image/upload/v1727798321/icon-arrow-circle-left-black_mxyonz.svg');
  background-repeat: no-repeat;
}

.full-width-hero-video-asset::-webkit-media-controls {
  display: none !important;
}

.full-width-hero-video-asset::-webkit-media-controls-start-playback-button {
  display: none !important;
  -webkit-appearance: none;
}

.full-width-hero-video-asset::-webkit-media-controls-overlay-play-button {
  display: none !important;
}

.full-width-hero-video-asset::-webkit-media-controls-play-button {
  display: none !important;
}

.full-width-hero-video-asset::-webkit-media-controls-enclosure {
  display: none !important;
}

.full-width-hero-video-asset::-webkit-media-controls-panel {
  display: none !important;
}

:root {
  --sticky-form-color: #fefaf5;
}

.sticky-input .error-message {
  font-size: 12px;
  margin-top: 6px;
}

.submission-arrow-mobile {
  display: none;
}

.sticky-input::before {
  content: '';
  position: absolute;
  top: -16px;
  left: 50%;
  width: 100vw;
  height: 80px;
  transform: translateX(-50%);
  background-color: transparent;
  animation: show-sticky-form 0.4s ease forwards;
  transition: height 0.3s ease;
  z-index: -1;
}

#sticky-form .shared-button-styles {
  @apply rounded-r-[4px] lg:rounded-l-none rounded-l-[4px];
}

#sticky-form input {
  @apply rounded-l-[4px] lg:rounded-r-none rounded-r-[4px];
}

#sticky-form input::placeholder {
  @apply text-12 md:text-14;
}

.sticky-input.error-state::before {
  content: '';
  position: absolute;
  top: -16px;
  left: 50%;
  width: 100vw;
  transform: translateX(-50%);
  background-color: transparent;
  animation: show-sticky-form 0.4s ease forwards;
  transition: height 0.3s ease;
  z-index: -1;
  height: 94px;
}

.sticky-input.success-state::before {
  animation: show-sticky-form-success 0.4s ease forwards;
}

@media (min-width: 768px) {
  .sticky-input.success-state::before {
    content: '';
    position: absolute;
    top: -16px;
    left: 50%;
    width: 100vw;
    transform: translateX(-50%);
    background-color: transparent;
    animation: show-sticky-form 0.4s ease forwards;
    transition: height 0.3s ease;
    z-index: -1;
    height: 94px;
  }
  .sticky-input::before {
    border-bottom: 1px solid transparent;
  }
}

@media (max-width: 768px) {
  :root {
    --sticky-form-color: #131313;
  }
  .sticky-input::before {
    height: 72px;
  }
  .sticky-input {
    width: 295px;
    margin: auto;
  }
  .sticky-input .submission-arrow-mobile {
    display: block;
    position: absolute;
    right: 10px;
    top: 14px;
  }
  .sticky-input button {
    display: none;
  }
  .sticky-container .error-message {
    margin: 8px 0 0 0;
  }
}

@keyframes show-sticky-form {
  to {
    border-color: #bdb1a4;
    background-color: var(--sticky-form-color);
  }
}

@keyframes show-sticky-form-success {
  to {
    background-color: #f3ebe2;
  }
}

@media (min-width: 821px) {
  .related-destination-box.category {
    flex: 0 0 30%;
  }
}

@media (max-width: 821px) {
  .tf-custom.tf-v1-widget-fullscreen {
    width: 100% !important;
  }
  #top-hotels-form .tf-custom .tf-v1-close-icon {
    @apply !text-sand text-48;
  }
}

.point-row::after {
  @apply bg-darkStone;
  content: '';
  position: absolute;
  width: 2px;
  height: calc(100% + 40px);
  top: 0;
  left: 50%;
  transform: translateX(-50%);
}

.full-width-hero-mobile {
  @apply !block md:!hidden;
}

.full-width-hero {
  @apply !hidden md:!block;
}

.info-grid-container {
  display: flex;
  flex-direction: column;
}

.infogrid-richtext .global-richtext p {
  @apply fora-text-body-1;
}

.three-col-infographic ul.stone-check li {
  @apply mb-0 lg:fora-text-h8 fora-text-h7 text-stone;
}

@media (max-width: 1024px) {
  ul.stone-check li::before {
    width: 10px !important;
    height: 10px !important;
    background-size: cover;
  }
  .three-col-infographic ul.stone-check li::before {
    top: 0;
    width: 16px !important;
    height: 16px !important;
    background-size: cover;
  }
  .three-col-infographic ul.stone-check li:not(:last-of-type) {
    margin-bottom: 16px;
  }
}

@media (min-width: 1024px) {
  .info-grid-container {
    display: block;
    columns: 150px 2;
    column-gap: 32px;
  }
}

.info-grid-child {
  page-break-inside: avoid;
}

.hotel-card-cta .shared-button-styles {
  @apply rounded-[8px];
}

.masonry-item {
  opacity: 0;
  transition: opacity 0.8s ease;
}

.destination-hero-wrapper .global-richtext p {
  @apply text-blackSand;
}

.masonry-wrapper {
  @apply -mx-2 -mt-2;
}

@media (min-width: 1024px) {
  .masonry-wrapper {
    @apply -mx-6 -mt-6;
  }
}

.destination-guides-carousel-content {
  @apply overflow-hidden overflow-ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3;
  line-clamp: 3;
  -webkit-box-orient: vertical;
}

.destination-guides-carousel-content p {
  @apply fora-text-body-2;
}

.aspect-360-384 {
  aspect-ratio: 360 / 384;
}

/* -- DROPDOWN MAIN NAVIGATION BAR -- */

.dropdown {
  display: none;
  position: absolute;
  opacity: 0;
  height: 0;
  left: 20px;
  padding: 5px 0;
  backdrop-filter: blur(0.3rem);
}

.dropdown::before {
  @apply bg-sand h-0 w-full top-0 left-0 absolute content-[""] backdrop-blur-2xl opacity-[0.85];
}

.dropdown.show {
  animation: ani-small 0.2s ease forwards;
  opacity: 1;
  display: block;
}

.dropdown.show::before {
  animation: ani-small-bg 0.2s ease forwards;
  opacity: 1;
  display: block;
  top: 0px;
}

.dropdown.override-height {
  height: fit-content !important;
}

.dropdown.show.w-banner {
  top: 117px;
  width: 100%;
  right: 0;
}

.dropdown a span {
  text-wrap: nowrap;
}

.dropdown-t {
  position: absolute;
  width: 100%;
  opacity: 0;
  height: 0;
  display: none;
  left: 20px;
  z-index: 100;
  backdrop-filter: blur(0.3rem);
}

.dropdown-t::before {
  @apply bg-sand h-0 w-full top-0 left-0 absolute content-[""] backdrop-blur-2xl opacity-[0.85];
}

.dropdown-t.show {
  animation: large-dropdown 0.4s ease forwards;
  height: 0;
  opacity: 0;
  pointer-events: all;
  height: auto;
  max-height: 320px;
  display: block;
}

.dropdown-t.show::before {
  animation: large-dropdown-bg 0.4s ease forwards;
  height: 0;
  opacity: 0;
}

@keyframes large-dropdown {
  50% {
    opacity: 0;
  }
  100% {
    height: 320px;
    opacity: 1;
  }
}
@keyframes large-dropdown-bg {
  50% {
    opacity: 0;
  }
  100% {
    height: 320px;
    opacity: 0.85;
  }
}

@keyframes ani-small {
  0% {
    height: 0;
    opacity: 0;
    top: 84px;
  }
  100% {
    height: 195px;
    opacity: 1;
    top: 84px;
  }
}

@keyframes ani-small-bg {
  0% {
    height: 0;
    opacity: 0;
  }
  100% {
    height: 100%;
    opacity: 0.85;
  }
}

@media (max-width: 1024px) {
  .dropdown {
    display: none;
    position: relative;
    height: unset;
    top: 0;
    opacity: unset;
  }
  .dropdown.show {
    opacity: unset;
    animation: none;
    display: block;
  }
  .dropdown-mobile {
    position: absolute;
    top: 0;
    z-index: 100000;
    width: 100vw;
    height: auto;
    min-height: 100vh;
    left: 0;
    @apply !bg-sand;
  }
  .dropdown-mobile.show li:first-of-type:before {
    content: '';
    position: absolute;
    width: calc(100% - 20px);
    height: 1px;
    bottom: 0;
    @apply bg-darkShell;
    left: 50%;
    transform: translateX(-50%);
  }
}

.backdrop-blur-bg {
  @apply before:top-0 before:h-full before:left-0 before:w-full before:absolute before:content-[""] before:!bg-opacity-90 before:backdrop-blur-[.3rem];
}
